
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Johan',

    metaInfo: { title: 'Johan Millving' },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'johan-bilder',
        'johan-videos',
        'johan-cv',
        'johan-social',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'johan',
      },
    },
  }
</script>
